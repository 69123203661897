import { initClickableArea, tabHighlight, tabHighlightRemove } from "./components/global_accessibility";
//import { setCookie, deleteCookie, dbyh_ajax_call, initLightbox } from "./components/global_functions";
import "@lottiefiles/lottie-player";

export default {
	init() {
		// JavaScript to be fired on all pages

		// Accessibility: Handle tab highlighting
		//---------------------------------------------------------------------------
		$("body").on("keyup", "a, .btn, button, input, textarea, select, .clickable-area", function (e) {
			tabHighlight(e);
		});

		$("body").on("blur", ".tabbed", function (e) {
			tabHighlightRemove(e);
		});

		// Accessibility: Initiate Cickabel areas
		//---------------------------------------------------------------------------
		initClickableArea();

		// Toggle Attribute on all buttons with classname .expander
		//---------------------------------------------------------------------------
		$("button.expander").on("click", function () {
			if ($(this).attr("aria-expanded") === "false") {
				$(this).attr("aria-expanded", true);
			} else {
				$(this).attr("aria-expanded", false);
			}
		});

		// Responsive menu (Main)
		//---------------------------------------------------------------------------
		$("#toggle_navigation").on("click", function () {
			$(".navigation-holder").toggleClass("navigation-open");
			$(".menu-overlay").toggleClass("visible");
		});

		//Toggle with close button when open.
		$(".close-button").on("click", () => {
			$(".navigation-holder").toggleClass("navigation-open");
			$(".menu-overlay").toggleClass("visible");
		});

		//Toggle with menu overlay when open.
		$(".menu-overlay").on("click", () => {
			$(".navigation-holder").toggleClass("navigation-open");
			$(".menu-overlay").toggleClass("visible");
		});

		// Show submenu, on click
		//---------------------------------------------------------------------------
		$("button.menu-expand").on("click", function () {
			$(this).toggleClass("is-active");
			$(this).parent("li").children(".submenu").slideToggle(150);
		});

		// Lägg på classer på meny för att docka den vid scroll.
		//---------------------------------------------------------------------------
		var width = $(window).width();

		$(window).on("scroll", function(){
			if (width <= 720) {
				if ($(this).scrollTop() > 10) {
					$('#header').addClass('fixed');
				} else {
					$('#header').removeClass('fixed');
				}
			} else {
				if ($(this).scrollTop() > 80) {
					$('#header').addClass('fixed');
				} else {
					$('#header').removeClass('fixed');
				}
			}
		});

		$('button.aspire-submit').on("click", function(){
			$(this).closest('form').addClass('is-working');
		});

		// Initiate Lightbox functions
		//---------------------------------------------------------------------------
		// initLightbox();

		// AJAX function
		//---------------------------------------------------------------------------
		// $(".ajax-call-button").on("click", function() {
		//  dbyh_ajax_call();
		// });

		// Open / Close the adminbar panel
		//---------------------------------------------------------------------------
		const $AdminbarToggle = $("#dbyh-adminbar__toggle"),
			$Adminbar = $("#dbyh-adminbar"),
			$AdminbarSubmenu = $Adminbar.find(".item__submenu"),
			$AdminbarPusher = $(".adminbar-pusher");

		$AdminbarToggle.on("click", function () {
			$AdminbarToggle.toggleClass("is-active");
			$Adminbar.toggleClass("is-open");
			$AdminbarSubmenu.hide();
		});

		$Adminbar.find("li.has-submenu span").on("click", function () {
			$(this).closest("li.has-submenu").find(".item__submenu").slideToggle(200);
		});

		$AdminbarPusher.on("click", function (event) {
			if (event.target.classList.contains("adminbar-pusher")) {
				$AdminbarToggle.removeClass("is-active");
				$Adminbar.removeClass("is-open");
			}
		});
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
